<template>
  <b-row>
    <b-col
      md="6"
      sm="12"
    >
      <EditLocationForm
        :initial-form-data="location"
        :on-form-saved="onLocationSaved"
      />
    </b-col>
  </b-row>
</template>

<script>
import EditLocationForm from '@/views/pages/locations/EditLocationForm.vue'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    EditLocationForm,
    BRow,
    BCol,
  },
  data() {
    return {
      location: {
        name: '',
        address: '',
        settings: {
          has_kids_checkin: false,
        },
      },
    }
  },
  methods: {
    onLocationSaved(apiResponse) {
      const self = this
      // after saving a new location, redirect to its edit for where all the other options like services are
      self.$router.push({
        name: 'edit-location',
        params: { locationId: apiResponse.data.location.id },
      })
    },
  },
}
</script>
